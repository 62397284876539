var olm_exports = {};
var onInitSuccess;
var onInitFail;

var Module = (function() {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  return (
function(Module) {
  Module = Module || {};

var a;a||(a=typeof Module !== 'undefined' ? Module : {});var aa;if("undefined"!==typeof window)aa=function(b){window.crypto.getRandomValues(b)};else if(module.exports){var ba=require("crypto");aa=function(b){var c=ba.randomBytes(b.length);b.set(c)};process=global.process}else throw Error("Cannot find global to attach library to");if("undefined"!==typeof OLM_OPTIONS)for(var ca in OLM_OPTIONS)OLM_OPTIONS.hasOwnProperty(ca)&&(a[ca]=OLM_OPTIONS[ca]);
a.onRuntimeInitialized=function(){g=a._olm_error();olm_exports.PRIVATE_KEY_LENGTH=a._olm_pk_private_key_length();onInitSuccess&&onInitSuccess()};a.onAbort=function(b){onInitFail&&onInitFail(b)};var da={},h;for(h in a)a.hasOwnProperty(h)&&(da[h]=a[h]);a.arguments=[];a.thisProgram="./this.program";a.quit=function(b,c){throw c;};a.preRun=[];a.postRun=[];var m=!1,n=!1,ea=!1,fa=!1;m="object"===typeof window;n="function"===typeof importScripts;
ea="object"===typeof process&&"function"===typeof require&&!m&&!n;fa=!m&&!ea&&!n;var p="";function ha(b){return a.locateFile?a.locateFile(b,p):p+b}
if(ea){p=__dirname+"/";var ia,ja;a.read=function(b,c){ia||(ia=require("fs"));ja||(ja=require("path"));b=ja.normalize(b);b=ia.readFileSync(b);return c?b:b.toString()};a.readBinary=function(b){b=a.read(b,!0);b.buffer||(b=new Uint8Array(b));assert(b.buffer);return b};1<process.argv.length&&(a.thisProgram=process.argv[1].replace(/\\/g,"/"));a.arguments=process.argv.slice(2);process.on("uncaughtException",function(b){throw b;});process.on("unhandledRejection",function(){process.exit(1)});a.quit=function(b){process.exit(b)};
a.inspect=function(){return"[Emscripten Module object]"}}else if(fa)"undefined"!=typeof read&&(a.read=function(b){return read(b)}),a.readBinary=function(b){if("function"===typeof readbuffer)return new Uint8Array(readbuffer(b));b=read(b,"binary");assert("object"===typeof b);return b},"undefined"!=typeof scriptArgs?a.arguments=scriptArgs:"undefined"!=typeof arguments&&(a.arguments=arguments),"function"===typeof quit&&(a.quit=function(b){quit(b)});else if(m||n)m?document.currentScript&&(p=document.currentScript.src):
p=self.location.href,_scriptDir&&(p=_scriptDir),0!==p.indexOf("blob:")?p=p.substr(0,p.lastIndexOf("/")+1):p="",a.read=function(b){var c=new XMLHttpRequest;c.open("GET",b,!1);c.send(null);return c.responseText},n&&(a.readBinary=function(b){var c=new XMLHttpRequest;c.open("GET",b,!1);c.responseType="arraybuffer";c.send(null);return new Uint8Array(c.response)}),a.readAsync=function(b,c,d){var e=new XMLHttpRequest;e.open("GET",b,!0);e.responseType="arraybuffer";e.onload=function(){200==e.status||0==e.status&&
e.response?c(e.response):d()};e.onerror=d;e.send(null)},a.setWindowTitle=function(b){document.title=b};var ka=a.print||("undefined"!==typeof console?console.log.bind(console):"undefined"!==typeof print?print:null),q=a.printErr||("undefined"!==typeof printErr?printErr:"undefined"!==typeof console&&console.warn.bind(console)||ka);for(h in da)da.hasOwnProperty(h)&&(a[h]=da[h]);da=void 0;function la(b){var c=r;r=r+b+15&-16;return c}
function ma(b){var c=u[v>>2];b=c+b+15&-16;u[v>>2]=b;if(b=b>=w)na(),b=!0;return b?(u[v>>2]=c,0):c}function oa(b){var c;c||(c=16);return Math.ceil(b/c)*c}var pa={"f64-rem":function(b,c){return b%c},"debugger":function(){debugger}},qa=!1;function assert(b,c){b||x("Assertion failed: "+c)}
function y(b){var c="i8";"*"===c.charAt(c.length-1)&&(c="i32");switch(c){case "i1":A[b>>0]=0;break;case "i8":A[b>>0]=0;break;case "i16":ra[b>>1]=0;break;case "i32":u[b>>2]=0;break;case "i64":tempI64=[0,(tempDouble=0,1<=+sa(tempDouble)?0<tempDouble?(ta(+ua(tempDouble/4294967296),4294967295)|0)>>>0:~~+va((tempDouble-+(~~tempDouble>>>0))/4294967296)>>>0:0)];u[b>>2]=tempI64[0];u[b+4>>2]=tempI64[1];break;case "float":wa[b>>2]=0;break;case "double":xa[b>>3]=0;break;default:x("invalid type for setValue: "+
c)}}function ya(b,c){c=c||"i8";"*"===c.charAt(c.length-1)&&(c="i32");switch(c){case "i1":return A[b>>0];case "i8":return A[b>>0];case "i16":return ra[b>>1];case "i32":return u[b>>2];case "i64":return u[b>>2];case "float":return wa[b>>2];case "double":return xa[b>>3];default:x("invalid type for getValue: "+c)}return null}
function B(b){var c=a.ALLOC_STACK;if("number"===typeof b){var d=!0;var e=b}else d=!1,e=b.length;c=4==c?f:["function"===typeof za?za:la,Aa,la,ma][void 0===c?2:c](Math.max(e,1));if(d){var f=c;assert(0==(c&3));for(b=c+(e&-4);f<b;f+=4)u[f>>2]=0;for(b=c+e;f<b;)A[f++>>0]=0;return c}b.subarray||b.slice?C.set(b,c):C.set(new Uint8Array(b),c);return c}var Ba="undefined"!==typeof TextDecoder?new TextDecoder("utf8"):void 0;
function D(b){for(var c=C,d=b;c[d];)++d;if(16<d-b&&c.subarray&&Ba)return Ba.decode(c.subarray(b,d));for(d="";;){var e=c[b++];if(!e)return d;if(e&128){var f=c[b++]&63;if(192==(e&224))d+=String.fromCharCode((e&31)<<6|f);else{var l=c[b++]&63;if(224==(e&240))e=(e&15)<<12|f<<6|l;else{var k=c[b++]&63;if(240==(e&248))e=(e&7)<<18|f<<12|l<<6|k;else{var t=c[b++]&63;if(248==(e&252))e=(e&3)<<24|f<<18|l<<12|k<<6|t;else{var I=c[b++]&63;e=(e&1)<<30|f<<24|l<<18|k<<12|t<<6|I}}}65536>e?d+=String.fromCharCode(e):(e-=
65536,d+=String.fromCharCode(55296|e>>10,56320|e&1023))}}else d+=String.fromCharCode(e)}}
function Ca(b,c,d,e){if(!(0<e))return 0;var f=d;e=d+e-1;for(var l=0;l<b.length;++l){var k=b.charCodeAt(l);if(55296<=k&&57343>=k){var t=b.charCodeAt(++l);k=65536+((k&1023)<<10)|t&1023}if(127>=k){if(d>=e)break;c[d++]=k}else{if(2047>=k){if(d+1>=e)break;c[d++]=192|k>>6}else{if(65535>=k){if(d+2>=e)break;c[d++]=224|k>>12}else{if(2097151>=k){if(d+3>=e)break;c[d++]=240|k>>18}else{if(67108863>=k){if(d+4>=e)break;c[d++]=248|k>>24}else{if(d+5>=e)break;c[d++]=252|k>>30;c[d++]=128|k>>24&63}c[d++]=128|k>>18&63}c[d++]=
128|k>>12&63}c[d++]=128|k>>6&63}c[d++]=128|k&63}}c[d]=0;return d-f}function Da(b){for(var c=0,d=0;d<b.length;++d){var e=b.charCodeAt(d);55296<=e&&57343>=e&&(e=65536+((e&1023)<<10)|b.charCodeAt(++d)&1023);127>=e?++c:c=2047>=e?c+2:65535>=e?c+3:2097151>=e?c+4:67108863>=e?c+5:c+6}return c}"undefined"!==typeof TextDecoder&&new TextDecoder("utf-16le");var buffer,A,C,ra,u,wa,xa;
function Ea(){a.HEAP8=A=new Int8Array(buffer);a.HEAP16=ra=new Int16Array(buffer);a.HEAP32=u=new Int32Array(buffer);a.HEAPU8=C=new Uint8Array(buffer);a.HEAPU16=new Uint16Array(buffer);a.HEAPU32=new Uint32Array(buffer);a.HEAPF32=wa=new Float32Array(buffer);a.HEAPF64=xa=new Float64Array(buffer)}var Fa,r,Ga,Ha,Ia,Ja,v;Fa=r=Ga=Ha=Ia=Ja=v=0;
function na(){x("Cannot enlarge memory arrays. Either (1) compile with  -s TOTAL_MEMORY=X  with X higher than the current value "+w+", (2) compile with  -s ALLOW_MEMORY_GROWTH=1  which allows increasing the size at runtime, or (3) if you want malloc to return NULL (0) instead of this abort, compile with  -s ABORTING_MALLOC=0 ")}var Ka=a.TOTAL_STACK||65536,w=a.TOTAL_MEMORY||262144;w<Ka&&q("TOTAL_MEMORY should be larger than TOTAL_STACK, was "+w+"! (TOTAL_STACK="+Ka+")");
a.buffer?buffer=a.buffer:("object"===typeof WebAssembly&&"function"===typeof WebAssembly.Memory?(a.wasmMemory=new WebAssembly.Memory({initial:w/65536,maximum:w/65536}),buffer=a.wasmMemory.buffer):buffer=new ArrayBuffer(w),a.buffer=buffer);Ea();function La(b){for(;0<b.length;){var c=b.shift();if("function"==typeof c)c();else{var d=c.h;"number"===typeof d?void 0===c.c?a.dynCall_v(d):a.dynCall_vi(d,c.c):d(void 0===c.c?null:c.c)}}}var Ma=[],Na=[],Oa=[],Pa=[],Qa=!1;
function Ra(){var b=a.preRun.shift();Ma.unshift(b)}function Ta(b,c){for(var d=0;d<b.length;++d)A[c++>>0]=b.charCodeAt(d)}var sa=Math.abs,va=Math.ceil,ua=Math.floor,ta=Math.min,E=0,Ua=null,Va=null;a.preloadedImages={};a.preloadedAudios={};function Wa(b){return String.prototype.startsWith?b.startsWith("data:application/octet-stream;base64,"):0===b.indexOf("data:application/octet-stream;base64,")}
(function(){function b(){try{if(a.wasmBinary)return new Uint8Array(a.wasmBinary);if(a.readBinary)return a.readBinary(f);throw"both async and sync fetching of the wasm failed";}catch(z){x(z)}}function c(){return a.wasmBinary||!m&&!n||"function"!==typeof fetch?new Promise(function(c){c(b())}):fetch(f,{credentials:"same-origin"}).then(function(b){if(!b.ok)throw"failed to load wasm binary file at '"+f+"'";return b.arrayBuffer()}).catch(function(){return b()})}function d(b){function d(b){t=b.exports;if(t.memory){b=
t.memory;var c=a.buffer;b.byteLength<c.byteLength&&q("the new buffer in mergeMemory is smaller than the previous one. in native wasm, we should grow memory here");c=new Int8Array(c);(new Int8Array(b)).set(c);a.buffer=buffer=b;Ea()}a.asm=t;a.usingWasm=!0;E--;a.monitorRunDependencies&&a.monitorRunDependencies(E);0==E&&(null!==Ua&&(clearInterval(Ua),Ua=null),Va&&(b=Va,Va=null,b()))}function e(b){d(b.instance)}function l(b){c().then(function(b){return WebAssembly.instantiate(b,k)}).then(b).catch(function(b){q("failed to asynchronously prepare wasm: "+
b);x(b)})}if("object"!==typeof WebAssembly)return q("no native wasm support detected"),!1;if(!(a.wasmMemory instanceof WebAssembly.Memory))return q("no native wasm Memory in use"),!1;b.memory=a.wasmMemory;k.global={NaN:NaN,Infinity:Infinity};k["global.Math"]=Math;k.env=b;E++;a.monitorRunDependencies&&a.monitorRunDependencies(E);if(a.instantiateWasm)try{return a.instantiateWasm(k,d)}catch(db){return q("Module.instantiateWasm callback failed with error: "+db),!1}a.wasmBinary||"function"!==typeof WebAssembly.instantiateStreaming||
Wa(f)||"function"!==typeof fetch?l(e):WebAssembly.instantiateStreaming(fetch(f,{credentials:"same-origin"}),k).then(e).catch(function(b){q("wasm streaming compile failed: "+b);q("falling back to ArrayBuffer instantiation");l(e)});return{}}var e="olm.wast",f="olm.wasm",l="olm.temp.asm.js";Wa(e)||(e=ha(e));Wa(f)||(f=ha(f));Wa(l)||(l=ha(l));var k={global:null,env:null,asm2wasm:pa,parent:a},t=null;a.asmPreload=a.asm;var I=a.reallocBuffer;a.reallocBuffer=function(b){if("asmjs"===P)var c=I(b);else a:{var d=
a.usingWasm?65536:16777216;0<b%d&&(b+=d-b%d);d=a.buffer.byteLength;if(a.usingWasm)try{c=-1!==a.wasmMemory.grow((b-d)/65536)?a.buffer=a.wasmMemory.buffer:null;break a}catch(hb){c=null;break a}c=void 0}return c};var P="";a.asm=function(b,c){if(!c.table){b=a.wasmTableSize;void 0===b&&(b=1024);var e=a.wasmMaxTableSize;c.table="object"===typeof WebAssembly&&"function"===typeof WebAssembly.Table?void 0!==e?new WebAssembly.Table({initial:b,maximum:e,element:"anyfunc"}):new WebAssembly.Table({initial:b,element:"anyfunc"}):
Array(b);a.wasmTable=c.table}c.memoryBase||(c.memoryBase=a.STATIC_BASE);c.tableBase||(c.tableBase=0);c=d(c);assert(c,"no binaryen method succeeded.");return c}})();Fa=1024;r=Fa+40112;Na.push();a.STATIC_BASE=Fa;a.STATIC_BUMP=40112;r+=16;v=la(4);Ga=Ha=oa(r);Ia=Ga+Ka;Ja=oa(Ia);u[v>>2]=Ja;function F(b){var c=Array(Da(b)+1);b=Ca(b,c,0,c.length);c.length=b;return c}a.wasmTableSize=12;a.wasmMaxTableSize=12;a.f={};
a.g={abort:x,enlargeMemory:function(){na()},getTotalMemory:function(){return w},abortOnCannotGrowMemory:na,___setErrNo:function(b){a.___errno_location&&(u[a.___errno_location()>>2]=b);return b},_emscripten_memcpy_big:function(b,c,d){C.set(C.subarray(c,c+d),b);return b},DYNAMICTOP_PTR:v,STACKTOP:Ha};var Xa=a.asm(a.f,a.g,buffer);a.asm=Xa;a._free=function(){return a.asm._free.apply(null,arguments)};var za=a._malloc=function(){return a.asm._malloc.apply(null,arguments)};
a._olm_account=function(){return a.asm._olm_account.apply(null,arguments)};a._olm_account_generate_one_time_keys=function(){return a.asm._olm_account_generate_one_time_keys.apply(null,arguments)};a._olm_account_generate_one_time_keys_random_length=function(){return a.asm._olm_account_generate_one_time_keys_random_length.apply(null,arguments)};a._olm_account_identity_keys=function(){return a.asm._olm_account_identity_keys.apply(null,arguments)};
a._olm_account_identity_keys_length=function(){return a.asm._olm_account_identity_keys_length.apply(null,arguments)};a._olm_account_last_error=function(){return a.asm._olm_account_last_error.apply(null,arguments)};a._olm_account_mark_keys_as_published=function(){return a.asm._olm_account_mark_keys_as_published.apply(null,arguments)};a._olm_account_max_number_of_one_time_keys=function(){return a.asm._olm_account_max_number_of_one_time_keys.apply(null,arguments)};
a._olm_account_one_time_keys=function(){return a.asm._olm_account_one_time_keys.apply(null,arguments)};a._olm_account_one_time_keys_length=function(){return a.asm._olm_account_one_time_keys_length.apply(null,arguments)};a._olm_account_sign=function(){return a.asm._olm_account_sign.apply(null,arguments)};a._olm_account_signature_length=function(){return a.asm._olm_account_signature_length.apply(null,arguments)};a._olm_account_size=function(){return a.asm._olm_account_size.apply(null,arguments)};
a._olm_clear_account=function(){return a.asm._olm_clear_account.apply(null,arguments)};a._olm_clear_inbound_group_session=function(){return a.asm._olm_clear_inbound_group_session.apply(null,arguments)};a._olm_clear_outbound_group_session=function(){return a.asm._olm_clear_outbound_group_session.apply(null,arguments)};a._olm_clear_pk_decryption=function(){return a.asm._olm_clear_pk_decryption.apply(null,arguments)};
a._olm_clear_pk_encryption=function(){return a.asm._olm_clear_pk_encryption.apply(null,arguments)};a._olm_clear_pk_signing=function(){return a.asm._olm_clear_pk_signing.apply(null,arguments)};a._olm_clear_sas=function(){return a.asm._olm_clear_sas.apply(null,arguments)};a._olm_clear_session=function(){return a.asm._olm_clear_session.apply(null,arguments)};a._olm_clear_utility=function(){return a.asm._olm_clear_utility.apply(null,arguments)};
a._olm_create_account=function(){return a.asm._olm_create_account.apply(null,arguments)};a._olm_create_account_random_length=function(){return a.asm._olm_create_account_random_length.apply(null,arguments)};a._olm_create_inbound_session=function(){return a.asm._olm_create_inbound_session.apply(null,arguments)};a._olm_create_inbound_session_from=function(){return a.asm._olm_create_inbound_session_from.apply(null,arguments)};
a._olm_create_outbound_session=function(){return a.asm._olm_create_outbound_session.apply(null,arguments)};a._olm_create_outbound_session_random_length=function(){return a.asm._olm_create_outbound_session_random_length.apply(null,arguments)};a._olm_create_sas=function(){return a.asm._olm_create_sas.apply(null,arguments)};a._olm_create_sas_random_length=function(){return a.asm._olm_create_sas_random_length.apply(null,arguments)};a._olm_decrypt=function(){return a.asm._olm_decrypt.apply(null,arguments)};
a._olm_decrypt_max_plaintext_length=function(){return a.asm._olm_decrypt_max_plaintext_length.apply(null,arguments)};a._olm_ed25519_verify=function(){return a.asm._olm_ed25519_verify.apply(null,arguments)};a._olm_encrypt=function(){return a.asm._olm_encrypt.apply(null,arguments)};a._olm_encrypt_message_length=function(){return a.asm._olm_encrypt_message_length.apply(null,arguments)};a._olm_encrypt_message_type=function(){return a.asm._olm_encrypt_message_type.apply(null,arguments)};
a._olm_encrypt_random_length=function(){return a.asm._olm_encrypt_random_length.apply(null,arguments)};a._olm_error=function(){return a.asm._olm_error.apply(null,arguments)};a._olm_export_inbound_group_session=function(){return a.asm._olm_export_inbound_group_session.apply(null,arguments)};a._olm_export_inbound_group_session_length=function(){return a.asm._olm_export_inbound_group_session_length.apply(null,arguments)};
a._olm_get_library_version=function(){return a.asm._olm_get_library_version.apply(null,arguments)};a._olm_group_decrypt=function(){return a.asm._olm_group_decrypt.apply(null,arguments)};a._olm_group_decrypt_max_plaintext_length=function(){return a.asm._olm_group_decrypt_max_plaintext_length.apply(null,arguments)};a._olm_group_encrypt=function(){return a.asm._olm_group_encrypt.apply(null,arguments)};
a._olm_group_encrypt_message_length=function(){return a.asm._olm_group_encrypt_message_length.apply(null,arguments)};a._olm_import_inbound_group_session=function(){return a.asm._olm_import_inbound_group_session.apply(null,arguments)};a._olm_inbound_group_session=function(){return a.asm._olm_inbound_group_session.apply(null,arguments)};a._olm_inbound_group_session_first_known_index=function(){return a.asm._olm_inbound_group_session_first_known_index.apply(null,arguments)};
a._olm_inbound_group_session_id=function(){return a.asm._olm_inbound_group_session_id.apply(null,arguments)};a._olm_inbound_group_session_id_length=function(){return a.asm._olm_inbound_group_session_id_length.apply(null,arguments)};a._olm_inbound_group_session_is_verified=function(){return a.asm._olm_inbound_group_session_is_verified.apply(null,arguments)};a._olm_inbound_group_session_last_error=function(){return a.asm._olm_inbound_group_session_last_error.apply(null,arguments)};
a._olm_inbound_group_session_size=function(){return a.asm._olm_inbound_group_session_size.apply(null,arguments)};a._olm_init_inbound_group_session=function(){return a.asm._olm_init_inbound_group_session.apply(null,arguments)};a._olm_init_outbound_group_session=function(){return a.asm._olm_init_outbound_group_session.apply(null,arguments)};a._olm_init_outbound_group_session_random_length=function(){return a.asm._olm_init_outbound_group_session_random_length.apply(null,arguments)};
a._olm_matches_inbound_session=function(){return a.asm._olm_matches_inbound_session.apply(null,arguments)};a._olm_matches_inbound_session_from=function(){return a.asm._olm_matches_inbound_session_from.apply(null,arguments)};a._olm_outbound_group_session=function(){return a.asm._olm_outbound_group_session.apply(null,arguments)};a._olm_outbound_group_session_id=function(){return a.asm._olm_outbound_group_session_id.apply(null,arguments)};
a._olm_outbound_group_session_id_length=function(){return a.asm._olm_outbound_group_session_id_length.apply(null,arguments)};a._olm_outbound_group_session_key=function(){return a.asm._olm_outbound_group_session_key.apply(null,arguments)};a._olm_outbound_group_session_key_length=function(){return a.asm._olm_outbound_group_session_key_length.apply(null,arguments)};a._olm_outbound_group_session_last_error=function(){return a.asm._olm_outbound_group_session_last_error.apply(null,arguments)};
a._olm_outbound_group_session_message_index=function(){return a.asm._olm_outbound_group_session_message_index.apply(null,arguments)};a._olm_outbound_group_session_size=function(){return a.asm._olm_outbound_group_session_size.apply(null,arguments)};a._olm_pickle_account=function(){return a.asm._olm_pickle_account.apply(null,arguments)};a._olm_pickle_account_length=function(){return a.asm._olm_pickle_account_length.apply(null,arguments)};
a._olm_pickle_inbound_group_session=function(){return a.asm._olm_pickle_inbound_group_session.apply(null,arguments)};a._olm_pickle_inbound_group_session_length=function(){return a.asm._olm_pickle_inbound_group_session_length.apply(null,arguments)};a._olm_pickle_outbound_group_session=function(){return a.asm._olm_pickle_outbound_group_session.apply(null,arguments)};a._olm_pickle_outbound_group_session_length=function(){return a.asm._olm_pickle_outbound_group_session_length.apply(null,arguments)};
a._olm_pickle_pk_decryption=function(){return a.asm._olm_pickle_pk_decryption.apply(null,arguments)};a._olm_pickle_pk_decryption_length=function(){return a.asm._olm_pickle_pk_decryption_length.apply(null,arguments)};a._olm_pickle_session=function(){return a.asm._olm_pickle_session.apply(null,arguments)};a._olm_pickle_session_length=function(){return a.asm._olm_pickle_session_length.apply(null,arguments)};a._olm_pk_ciphertext_length=function(){return a.asm._olm_pk_ciphertext_length.apply(null,arguments)};
a._olm_pk_decrypt=function(){return a.asm._olm_pk_decrypt.apply(null,arguments)};a._olm_pk_decryption=function(){return a.asm._olm_pk_decryption.apply(null,arguments)};a._olm_pk_decryption_last_error=function(){return a.asm._olm_pk_decryption_last_error.apply(null,arguments)};a._olm_pk_decryption_size=function(){return a.asm._olm_pk_decryption_size.apply(null,arguments)};a._olm_pk_encrypt=function(){return a.asm._olm_pk_encrypt.apply(null,arguments)};
a._olm_pk_encrypt_random_length=function(){return a.asm._olm_pk_encrypt_random_length.apply(null,arguments)};a._olm_pk_encryption=function(){return a.asm._olm_pk_encryption.apply(null,arguments)};a._olm_pk_encryption_last_error=function(){return a.asm._olm_pk_encryption_last_error.apply(null,arguments)};a._olm_pk_encryption_set_recipient_key=function(){return a.asm._olm_pk_encryption_set_recipient_key.apply(null,arguments)};
a._olm_pk_encryption_size=function(){return a.asm._olm_pk_encryption_size.apply(null,arguments)};a._olm_pk_generate_key=function(){return a.asm._olm_pk_generate_key.apply(null,arguments)};a._olm_pk_generate_key_random_length=function(){return a.asm._olm_pk_generate_key_random_length.apply(null,arguments)};a._olm_pk_get_private_key=function(){return a.asm._olm_pk_get_private_key.apply(null,arguments)};a._olm_pk_key_from_private=function(){return a.asm._olm_pk_key_from_private.apply(null,arguments)};
a._olm_pk_key_length=function(){return a.asm._olm_pk_key_length.apply(null,arguments)};a._olm_pk_mac_length=function(){return a.asm._olm_pk_mac_length.apply(null,arguments)};a._olm_pk_max_plaintext_length=function(){return a.asm._olm_pk_max_plaintext_length.apply(null,arguments)};a._olm_pk_private_key_length=function(){return a.asm._olm_pk_private_key_length.apply(null,arguments)};a._olm_pk_sign=function(){return a.asm._olm_pk_sign.apply(null,arguments)};
a._olm_pk_signature_length=function(){return a.asm._olm_pk_signature_length.apply(null,arguments)};a._olm_pk_signing=function(){return a.asm._olm_pk_signing.apply(null,arguments)};a._olm_pk_signing_key_from_seed=function(){return a.asm._olm_pk_signing_key_from_seed.apply(null,arguments)};a._olm_pk_signing_last_error=function(){return a.asm._olm_pk_signing_last_error.apply(null,arguments)};a._olm_pk_signing_public_key_length=function(){return a.asm._olm_pk_signing_public_key_length.apply(null,arguments)};
a._olm_pk_signing_seed_length=function(){return a.asm._olm_pk_signing_seed_length.apply(null,arguments)};a._olm_pk_signing_size=function(){return a.asm._olm_pk_signing_size.apply(null,arguments)};a._olm_remove_one_time_keys=function(){return a.asm._olm_remove_one_time_keys.apply(null,arguments)};a._olm_sas=function(){return a.asm._olm_sas.apply(null,arguments)};a._olm_sas_calculate_mac=function(){return a.asm._olm_sas_calculate_mac.apply(null,arguments)};
a._olm_sas_calculate_mac_long_kdf=function(){return a.asm._olm_sas_calculate_mac_long_kdf.apply(null,arguments)};a._olm_sas_generate_bytes=function(){return a.asm._olm_sas_generate_bytes.apply(null,arguments)};a._olm_sas_get_pubkey=function(){return a.asm._olm_sas_get_pubkey.apply(null,arguments)};a._olm_sas_last_error=function(){return a.asm._olm_sas_last_error.apply(null,arguments)};a._olm_sas_mac_length=function(){return a.asm._olm_sas_mac_length.apply(null,arguments)};
a._olm_sas_pubkey_length=function(){return a.asm._olm_sas_pubkey_length.apply(null,arguments)};a._olm_sas_set_their_key=function(){return a.asm._olm_sas_set_their_key.apply(null,arguments)};a._olm_sas_size=function(){return a.asm._olm_sas_size.apply(null,arguments)};a._olm_session=function(){return a.asm._olm_session.apply(null,arguments)};a._olm_session_describe=function(){return a.asm._olm_session_describe.apply(null,arguments)};
a._olm_session_has_received_message=function(){return a.asm._olm_session_has_received_message.apply(null,arguments)};a._olm_session_id=function(){return a.asm._olm_session_id.apply(null,arguments)};a._olm_session_id_length=function(){return a.asm._olm_session_id_length.apply(null,arguments)};a._olm_session_last_error=function(){return a.asm._olm_session_last_error.apply(null,arguments)};a._olm_session_size=function(){return a.asm._olm_session_size.apply(null,arguments)};
a._olm_sha256=function(){return a.asm._olm_sha256.apply(null,arguments)};a._olm_sha256_length=function(){return a.asm._olm_sha256_length.apply(null,arguments)};a._olm_unpickle_account=function(){return a.asm._olm_unpickle_account.apply(null,arguments)};a._olm_unpickle_inbound_group_session=function(){return a.asm._olm_unpickle_inbound_group_session.apply(null,arguments)};a._olm_unpickle_outbound_group_session=function(){return a.asm._olm_unpickle_outbound_group_session.apply(null,arguments)};
a._olm_unpickle_pk_decryption=function(){return a.asm._olm_unpickle_pk_decryption.apply(null,arguments)};a._olm_unpickle_session=function(){return a.asm._olm_unpickle_session.apply(null,arguments)};a._olm_utility=function(){return a.asm._olm_utility.apply(null,arguments)};a._olm_utility_last_error=function(){return a.asm._olm_utility_last_error.apply(null,arguments)};a._olm_utility_size=function(){return a.asm._olm_utility_size.apply(null,arguments)};
var Aa=a.stackAlloc=function(){return a.asm.stackAlloc.apply(null,arguments)},Ya=a.stackRestore=function(){return a.asm.stackRestore.apply(null,arguments)},Za=a.stackSave=function(){return a.asm.stackSave.apply(null,arguments)};a.asm=Xa;a.ALLOC_STACK=1;a.then=function(b){if(a.calledRun)b(a);else{var c=a.onRuntimeInitialized;a.onRuntimeInitialized=function(){c&&c();b(a)}}return a};Va=function $a(){a.calledRun||ab();a.calledRun||(Va=$a)};
function ab(){function b(){if(!a.calledRun&&(a.calledRun=!0,!qa)){Qa||(Qa=!0,La(Na));La(Oa);if(a.onRuntimeInitialized)a.onRuntimeInitialized();if(a.postRun)for("function"==typeof a.postRun&&(a.postRun=[a.postRun]);a.postRun.length;){var b=a.postRun.shift();Pa.unshift(b)}La(Pa)}}if(!(0<E)){if(a.preRun)for("function"==typeof a.preRun&&(a.preRun=[a.preRun]);a.preRun.length;)Ra();La(Ma);0<E||a.calledRun||(a.setStatus?(a.setStatus("Running..."),setTimeout(function(){setTimeout(function(){a.setStatus("")},
1);b()},1)):b())}}a.run=ab;function x(b){if(a.onAbort)a.onAbort(b);void 0!==b?(ka(b),q(b),b=JSON.stringify(b)):b="";qa=!0;throw"abort("+b+"). Build with -s ASSERTIONS=1 for more info.";}a.abort=x;if(a.preInit)for("function"==typeof a.preInit&&(a.preInit=[a.preInit]);0<a.preInit.length;)a.preInit.pop()();a.noExitRuntime=!0;ab();function G(){var b=a._olm_outbound_group_session_size();this.b=H(b);this.a=a._olm_outbound_group_session(this.b)}
function J(b){return function(){var c=b.apply(this,arguments);if(c===g)throw c=D(a._olm_outbound_group_session_last_error(arguments[0])),Error("OLM."+c);return c}}G.prototype.free=function(){a._olm_clear_outbound_group_session(this.a);K(this.a)};G.prototype.pickle=L(function(b){b=F(b);var c=J(a._olm_pickle_outbound_group_session_length)(this.a),d=B(b),e=B(c+1);try{J(a._olm_pickle_outbound_group_session)(this.a,d,b.length,e,c)}finally{for(M(d,b.length),c=0;c<b.length;c++)b[c]=0}return D(e)});
G.prototype.unpickle=L(function(b,c){b=F(b);var d=B(b);c=F(c);var e=B(c);try{J(a._olm_unpickle_outbound_group_session)(this.a,d,b.length,e,c.length)}finally{for(M(d,b.length),d=0;d<b.length;d++)b[d]=0}});G.prototype.create=L(function(){var b=J(a._olm_init_outbound_group_session_random_length)(this.a),c=N(b);J(a._olm_init_outbound_group_session)(this.a,c,b)});
G.prototype.encrypt=function(b){try{var c=Da(b);var d=J(a._olm_group_encrypt_message_length)(this.a,c);var e=H(c+1);Ca(b,C,e,c+1);var f=H(d+1);J(a._olm_group_encrypt)(this.a,e,c,f,d);y(f+d);return D(f)}finally{void 0!==e&&(M(e,c+1),K(e)),void 0!==f&&K(f)}};G.prototype.session_id=L(function(){var b=J(a._olm_outbound_group_session_id_length)(this.a),c=B(b+1);J(a._olm_outbound_group_session_id)(this.a,c,b);return D(c)});
G.prototype.session_key=L(function(){var b=J(a._olm_outbound_group_session_key_length)(this.a),c=B(b+1);J(a._olm_outbound_group_session_key)(this.a,c,b);var d=D(c);M(c,b);return d});G.prototype.message_index=function(){return J(a._olm_outbound_group_session_message_index)(this.a)};olm_exports.OutboundGroupSession=G;function O(){var b=a._olm_inbound_group_session_size();this.b=H(b);this.a=a._olm_inbound_group_session(this.b)}
function Q(b){return function(){var c=b.apply(this,arguments);if(c===g)throw c=D(a._olm_inbound_group_session_last_error(arguments[0])),Error("OLM."+c);return c}}O.prototype.free=function(){a._olm_clear_inbound_group_session(this.a);K(this.a)};O.prototype.pickle=L(function(b){b=F(b);var c=Q(a._olm_pickle_inbound_group_session_length)(this.a),d=B(b),e=B(c+1);try{Q(a._olm_pickle_inbound_group_session)(this.a,d,b.length,e,c)}finally{for(M(d,b.length),c=0;c<b.length;c++)b[c]=0}return D(e)});
O.prototype.unpickle=L(function(b,c){b=F(b);var d=B(b);c=F(c);var e=B(c);try{Q(a._olm_unpickle_inbound_group_session)(this.a,d,b.length,e,c.length)}finally{for(M(d,b.length),d=0;d<b.length;d++)b[d]=0}});O.prototype.create=L(function(b){b=F(b);var c=B(b);try{Q(a._olm_init_inbound_group_session)(this.a,c,b.length)}finally{for(M(c,b.length),c=0;c<b.length;c++)b[c]=0}});
O.prototype.import_session=L(function(b){b=F(b);var c=B(b);try{Q(a._olm_import_inbound_group_session)(this.a,c,b.length)}finally{for(M(c,b.length),c=0;c<b.length;c++)b[c]=0}});O.prototype.decrypt=L(function(b){try{var c=H(b.length);Ta(b,c);var d=Q(a._olm_group_decrypt_max_plaintext_length)(this.a,c,b.length);Ta(b,c);var e=H(d+1);var f=B(4);var l=Q(a._olm_group_decrypt)(this.a,c,b.length,e,d,f);y(e+l);return{plaintext:D(e),message_index:ya(f,"i32")}}finally{void 0!==c&&K(c),void 0!==e&&(M(e,l),K(e))}});
O.prototype.session_id=L(function(){var b=Q(a._olm_inbound_group_session_id_length)(this.a),c=B(b+1);Q(a._olm_inbound_group_session_id)(this.a,c,b);return D(c)});O.prototype.first_known_index=L(function(){return Q(a._olm_inbound_group_session_first_known_index)(this.a)});O.prototype.export_session=L(function(b){var c=Q(a._olm_export_inbound_group_session_length)(this.a),d=B(c+1);J(a._olm_export_inbound_group_session)(this.a,d,c,b);b=D(d);M(d,c);return b});olm_exports.InboundGroupSession=O;
function bb(){var b=a._olm_pk_encryption_size();this.b=H(b);this.a=a._olm_pk_encryption(this.b)}function R(b){return function(){var c=b.apply(this,arguments);if(c===g)throw c=D(a._olm_pk_encryption_last_error(arguments[0])),Error("OLM."+c);return c}}bb.prototype.free=function(){a._olm_clear_pk_encryption(this.a);K(this.a)};bb.prototype.set_recipient_key=L(function(b){b=F(b);var c=B(b);R(a._olm_pk_encryption_set_recipient_key)(this.a,c,b.length)});
bb.prototype.encrypt=L(function(b){try{var c=Da(b);var d=H(c+1);Ca(b,C,d,c+1);var e=R(a._olm_pk_encrypt_random_length)();var f=N(e);var l=R(a._olm_pk_ciphertext_length)(this.a,c);var k=H(l+1);var t=R(a._olm_pk_mac_length)(this.a),I=B(t+1);y(I+t);var P=R(a._olm_pk_key_length)(),z=B(P+1);y(z+P);R(a._olm_pk_encrypt)(this.a,d,c,k,l,I,t,z,P,f,e);y(k+l);return{ciphertext:D(k),mac:D(I),ephemeral:D(z)}}finally{void 0!==f&&M(f,e),void 0!==d&&(M(d,c+1),K(d)),void 0!==k&&K(k)}});
function S(){var b=a._olm_pk_decryption_size();this.b=H(b);this.a=a._olm_pk_decryption(this.b)}function T(b){return function(){var c=b.apply(this,arguments);if(c===g)throw c=D(a._olm_pk_decryption_last_error(arguments[0])),Error("OLM."+c);return c}}S.prototype.free=function(){a._olm_clear_pk_decryption(this.a);K(this.a)};
S.prototype.init_with_private_key=L(function(b){var c=B(b.length);a.HEAPU8.set(b,c);var d=T(a._olm_pk_key_length)(),e=B(d+1);try{T(a._olm_pk_key_from_private)(this.a,e,d,c,b.length)}finally{M(c,b.length)}return D(e)});S.prototype.generate_key=L(function(){var b=T(a._olm_pk_private_key_length)(),c=N(b),d=T(a._olm_pk_key_length)(),e=B(d+1);try{T(a._olm_pk_key_from_private)(this.a,e,d,c,b)}finally{M(c,b)}return D(e)});
S.prototype.get_private_key=L(function(){var b=R(a._olm_pk_private_key_length)(),c=B(b);T(a._olm_pk_get_private_key)(this.a,c,b);var d=new Uint8Array(new Uint8Array(a.HEAPU8.buffer,c,b));M(c,b);return d});S.prototype.pickle=L(function(b){b=F(b);var c=T(a._olm_pickle_pk_decryption_length)(this.a),d=B(b),e=B(c+1);try{T(a._olm_pickle_pk_decryption)(this.a,d,b.length,e,c)}finally{for(M(d,b.length),c=0;c<b.length;c++)b[c]=0}return D(e)});
S.prototype.unpickle=L(function(b,c){b=F(b);var d=B(b),e=F(c),f=B(e),l=T(a._olm_pk_key_length)();c=B(l+1);try{T(a._olm_unpickle_pk_decryption)(this.a,d,b.length,f,e.length,c,l)}finally{for(M(d,b.length),d=0;d<b.length;d++)b[d]=0}return D(c)});
S.prototype.decrypt=L(function(b,c,d){try{var e=Da(d);var f=H(e+1);Ca(d,C,f,e+1);var l=F(b),k=B(l),t=F(c),I=B(t);var P=T(a._olm_pk_max_plaintext_length)(this.a,e);var z=H(P+1);var Sa=T(a._olm_pk_decrypt)(this.a,k,l.length,I,t.length,f,e,z,P);y(z+Sa);return D(z)}finally{void 0!==z&&(M(z,Sa+1),K(z)),void 0!==f&&K(f)}});function cb(){var b=a._olm_pk_signing_size();this.b=H(b);this.a=a._olm_pk_signing(this.b)}
function eb(b){return function(){var c=b.apply(this,arguments);if(c===g)throw c=D(a._olm_pk_signing_last_error(arguments[0])),Error("OLM."+c);return c}}cb.prototype.free=function(){a._olm_clear_pk_signing(this.a);K(this.a)};cb.prototype.init_with_seed=L(function(b){var c=B(b.length);a.HEAPU8.set(b,c);var d=eb(a._olm_pk_signing_public_key_length)(),e=B(d+1);try{eb(a._olm_pk_signing_key_from_seed)(this.a,e,d,c,b.length)}finally{M(c,b.length)}return D(e)});
cb.prototype.generate_seed=L(function(){var b=eb(a._olm_pk_signing_seed_length)(),c=N(b),d=new Uint8Array(new Uint8Array(a.HEAPU8.buffer,c,b));M(c,b);return d});cb.prototype.sign=L(function(b){try{var c=Da(b);var d=H(c+1);Ca(b,C,d,c+1);var e=eb(a._olm_pk_signature_length)(),f=B(e+1);eb(a._olm_pk_sign)(this.a,d,c,f,e);return D(f)}finally{void 0!==d&&(M(d,c+1),K(d))}});
function U(){var b=a._olm_sas_size(),c=a._olm_create_sas_random_length(),d=N(c);this.b=H(b);this.a=a._olm_sas(this.b);a._olm_create_sas(this.a,d,c);M(d,c)}function V(b){return function(){var c=b.apply(this,arguments);if(c===g)throw c=D(a._olm_sas_last_error(arguments[0])),Error("OLM."+c);return c}}U.prototype.free=function(){a._olm_clear_sas(this.a);K(this.a)};U.prototype.get_pubkey=L(function(){var b=V(a._olm_sas_pubkey_length)(this.a),c=B(b+1);V(a._olm_sas_get_pubkey)(this.a,c,b);return D(c)});
U.prototype.set_their_key=L(function(b){b=F(b);var c=B(b);V(a._olm_sas_set_their_key)(this.a,c,b.length)});U.prototype.generate_bytes=L(function(b,c){b=F(b);var d=B(b),e=B(c);V(a._olm_sas_generate_bytes)(this.a,d,b.length,e,c);return new Uint8Array(new Uint8Array(a.HEAPU8.buffer,e,c))});U.prototype.calculate_mac=L(function(b,c){b=F(b);var d=B(b);c=F(c);var e=B(c),f=V(a._olm_sas_mac_length)(this.a),l=B(f+1);V(a._olm_sas_calculate_mac)(this.a,d,b.length,e,c.length,l,f);return D(l)});
U.prototype.calculate_mac_long_kdf=L(function(b,c){b=F(b);var d=B(b);c=F(c);var e=B(c),f=V(a._olm_sas_mac_length)(this.a),l=B(f+1);V(a._olm_sas_calculate_mac_long_kdf)(this.a,d,b.length,e,c.length,l,f);return D(l)});var H=a._malloc,K=a._free,g;function N(b){var c=B(b);b=new Uint8Array(a.HEAPU8.buffer,c,b);aa(b);return c}function L(b){return function(){var c=Za();try{return b.apply(this,arguments)}finally{Ya(c)}}}function M(b,c){for(;0<c--;)a.HEAP8[b++]=0}
function W(){var b=a._olm_account_size();this.b=H(b);this.a=a._olm_account(this.b)}function X(b){return function(){var c=b.apply(this,arguments);if(c===g)throw c=D(a._olm_account_last_error(arguments[0])),Error("OLM."+c);return c}}W.prototype.free=function(){a._olm_clear_account(this.a);K(this.a)};W.prototype.create=L(function(){var b=X(a._olm_create_account_random_length)(this.a),c=N(b);X(a._olm_create_account)(this.a,c,b)});
W.prototype.identity_keys=L(function(){var b=X(a._olm_account_identity_keys_length)(this.a),c=B(b+1);X(a._olm_account_identity_keys)(this.a,c,b);return D(c)});W.prototype.sign=L(function(b){var c=X(a._olm_account_signature_length)(this.a);b=F(b);var d=B(b),e=B(c+1);try{X(a._olm_account_sign)(this.a,d,b.length,e,c)}finally{for(M(d,b.length),c=0;c<b.length;c++)b[c]=0}return D(e)});
W.prototype.one_time_keys=L(function(){var b=X(a._olm_account_one_time_keys_length)(this.a),c=B(b+1);X(a._olm_account_one_time_keys)(this.a,c,b);return D(c)});W.prototype.mark_keys_as_published=L(function(){X(a._olm_account_mark_keys_as_published)(this.a)});W.prototype.max_number_of_one_time_keys=L(function(){return X(a._olm_account_max_number_of_one_time_keys)(this.a)});
W.prototype.generate_one_time_keys=L(function(b){var c=X(a._olm_account_generate_one_time_keys_random_length)(this.a,b),d=N(c);X(a._olm_account_generate_one_time_keys)(this.a,b,d,c)});W.prototype.remove_one_time_keys=L(function(b){X(a._olm_remove_one_time_keys)(this.a,b.a)});W.prototype.pickle=L(function(b){b=F(b);var c=X(a._olm_pickle_account_length)(this.a),d=B(b),e=B(c+1);try{X(a._olm_pickle_account)(this.a,d,b.length,e,c)}finally{for(M(d,b.length),c=0;c<b.length;c++)b[c]=0}return D(e)});
W.prototype.unpickle=L(function(b,c){b=F(b);var d=B(b);c=F(c);var e=B(c);try{X(a._olm_unpickle_account)(this.a,d,b.length,e,c.length)}finally{for(M(d,b.length),d=0;d<b.length;d++)b[d]=0}});function Y(){var b=a._olm_session_size();this.b=H(b);this.a=a._olm_session(this.b)}function Z(b){return function(){var c=b.apply(this,arguments);if(c===g)throw c=D(a._olm_session_last_error(arguments[0])),Error("OLM."+c);return c}}Y.prototype.free=function(){a._olm_clear_session(this.a);K(this.a)};
Y.prototype.pickle=L(function(b){b=F(b);var c=Z(a._olm_pickle_session_length)(this.a),d=B(b),e=B(c+1);try{Z(a._olm_pickle_session)(this.a,d,b.length,e,c)}finally{for(M(d,b.length),c=0;c<b.length;c++)b[c]=0}return D(e)});Y.prototype.unpickle=L(function(b,c){b=F(b);var d=B(b);c=F(c);var e=B(c);try{Z(a._olm_unpickle_session)(this.a,d,b.length,e,c.length)}finally{for(M(d,b.length),d=0;d<b.length;d++)b[d]=0}});
Y.prototype.create_outbound=L(function(b,c,d){var e=Z(a._olm_create_outbound_session_random_length)(this.a),f=N(e);c=F(c);d=F(d);var l=B(c),k=B(d);try{Z(a._olm_create_outbound_session)(this.a,b.a,l,c.length,k,d.length,f,e)}finally{M(f,e)}});Y.prototype.create_inbound=L(function(b,c){c=F(c);var d=B(c);try{Z(a._olm_create_inbound_session)(this.a,b.a,d,c.length)}finally{for(M(d,c.length),b=0;b<c.length;b++)c[b]=0}});
Y.prototype.create_inbound_from=L(function(b,c,d){c=F(c);var e=B(c);d=F(d);var f=B(d);try{Z(a._olm_create_inbound_session_from)(this.a,b.a,e,c.length,f,d.length)}finally{for(M(f,d.length),b=0;b<d.length;b++)d[b]=0}});Y.prototype.session_id=L(function(){var b=Z(a._olm_session_id_length)(this.a),c=B(b+1);Z(a._olm_session_id)(this.a,c,b);return D(c)});Y.prototype.has_received_message=function(){return Z(a._olm_session_has_received_message)(this.a)?!0:!1};
Y.prototype.matches_inbound=L(function(b){b=F(b);var c=B(b);return Z(a._olm_matches_inbound_session)(this.a,c,b.length)?!0:!1});Y.prototype.matches_inbound_from=L(function(b,c){b=F(b);var d=B(b);c=F(c);var e=B(c);return Z(a._olm_matches_inbound_session_from)(this.a,d,b.length,e,c.length)?!0:!1});
Y.prototype.encrypt=L(function(b){try{var c=Z(a._olm_encrypt_random_length)(this.a);var d=Z(a._olm_encrypt_message_type)(this.a);var e=Da(b);var f=Z(a._olm_encrypt_message_length)(this.a,e);var l=N(c);var k=H(e+1);Ca(b,C,k,e+1);var t=H(f+1);Z(a._olm_encrypt)(this.a,k,e,l,c,t,f);y(t+f);return{type:d,body:D(t)}}finally{void 0!==l&&M(l,c),void 0!==k&&(M(k,e+1),K(k)),void 0!==t&&K(t)}});
Y.prototype.decrypt=L(function(b,c){try{var d=H(c.length);Ta(c,d);var e=Z(a._olm_decrypt_max_plaintext_length)(this.a,b,d,c.length);Ta(c,d);var f=H(e+1);var l=Z(a._olm_decrypt)(this.a,b,d,c.length,f,e);y(f+l);return D(f)}finally{void 0!==d&&K(d),void 0!==f&&(M(f,e),K(f))}});Y.prototype.describe=L(function(){try{var b=H(256);Z(a._olm_session_describe)(this.a,b,256);return D(b)}finally{void 0!==b&&K(b)}});function fb(){var b=a._olm_utility_size();this.b=H(b);this.a=a._olm_utility(this.b)}
function gb(b){return function(){var c=b.apply(this,arguments);if(c===g)throw c=D(a._olm_utility_last_error(arguments[0])),Error("OLM."+c);return c}}fb.prototype.free=function(){a._olm_clear_utility(this.a);K(this.a)};fb.prototype.sha256=L(function(b){var c=gb(a._olm_sha256_length)(this.a);b=F(b);var d=B(b),e=B(c+1);try{gb(a._olm_sha256)(this.a,d,b.length,e,c)}finally{for(M(d,b.length),c=0;c<b.length;c++)b[c]=0}return D(e)});
fb.prototype.ed25519_verify=L(function(b,c,d){b=F(b);var e=B(b);c=F(c);var f=B(c);d=F(d);var l=B(d);try{gb(a._olm_ed25519_verify)(this.a,e,b.length,f,c.length,l,d.length)}finally{for(M(f,c.length),b=0;b<c.length;b++)c[b]=0}});olm_exports.Account=W;olm_exports.Session=Y;olm_exports.Utility=fb;olm_exports.PkEncryption=bb;olm_exports.PkDecryption=S;olm_exports.PkSigning=cb;olm_exports.SAS=U;
olm_exports.get_library_version=L(function(){var b=B(3);a._olm_get_library_version(b,b+1,b+2);return[ya(b,"i8"),ya(b+1,"i8"),ya(b+2,"i8")]});


  return Module;
}
);
})();
if (typeof exports === 'object' && typeof module === 'object')
    module.exports = Module;
  else if (typeof define === 'function' && define['amd'])
    define([], function() { return Module; });
  else if (typeof exports === 'object')
    exports["Module"] = Module;
  var olmInitPromise;

olm_exports['init'] = function(opts) {
    if (olmInitPromise) return olmInitPromise;

    if (opts) OLM_OPTIONS = opts;

    olmInitPromise = new Promise(function(resolve, reject) {
        onInitSuccess = function() {
            resolve();
        };
        onInitFail = function(err) {
            reject(err);
        };
        Module();
    });
    return olmInitPromise;
};

if (typeof(window) !== 'undefined') {
    // We've been imported directly into a browser. Define the global 'Olm' object.
    // (we do this even if module.exports was defined, because it's useful to have
    // Olm in the global scope for browserified and webpacked apps.)
    window["Olm"] = olm_exports;
}

if (typeof module === 'object') {
    // Emscripten sets the module exports to be its module
    // with wrapped c functions. Clobber it with our higher
    // level wrapper class.
    module.exports = olm_exports;
}
